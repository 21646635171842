export function initGigyaScreen(screenSet, startScreen, containerID, data) {
    const { getCookie } = useHttpHelper();
    const params: any = {
        screenSet: screenSet || 'CAMBRIDGEONE-RegistrationLogin',
        startScreen: startScreen,
        containerID: containerID,
        lang: getCookie('c1_lang')?.toLocaleLowerCase() || 'en'
    };

    if (data?.context) {
        params.context = data.context;
    }

    if (startScreen == 'nemo-social-login' && data && data.enabledProviders) {
        //{NEMO-2225] for users invited by email add the enabledProviders param to show those social login icon(s) (facebook and google) through which the user had previously registered.
        params.enabledProviders = data.enabledProviders;
    }
    if (
        startScreen == 'nemo-register-learner' &&
        location.search.includes('ielts=true')
    ) {
        params.regSource = location.origin + location.pathname + '/ielts';
    }
    window.gigya.accounts.showScreenSet(params);
}

export function initSocialLoginScreen(provider, containerID) {
    window.gigya.accounts.socialLogin({
        provider: provider,
        extraFields: 'samlData',
        containerID: containerID
    });
}

export function registerGigyaEventListeners() {
    window.addEventListener('NemoNewScreen', (res) => {
        if (res.detail.path) {
            navigateTo({ path: res.detail.path });
        }
    });
}

export function attachGigyaScreensetStyles() {
    const assetsPath = useRuntimeConfig().public.cdnURL
        ? useRuntimeConfig().public.cdnURL + '/_nuxt/'
        : '_nuxt/assets/img/';
    const gigyaScreenPrefix = '.gigya-screen';
    const gigyaImgStyles = document.createElement('style');
    const gigyaCompositeControlPrefix =
        ' .gigya-composite-control.gigya-composite-control-social-login.nemo-social .gigya-login-providers-container .gigya-login-provider-row span.gigya-login-provider';
    const gigyaLinkAccountCompositeControlPrefix =
        ' .gigya-composite-control.gigya-composite-control-social-login.nemo-social-account-link .gigya-login-providers-container .gigya-login-provider-row span.gigya-login-provider';
    const gigyaStylePrefix = gigyaScreenPrefix + gigyaCompositeControlPrefix;
    const gigyaLinkAccountStylePrefix = gigyaScreenPrefix + gigyaLinkAccountCompositeControlPrefix;

    //cannot use template literals, it conflicts with html-webpack-plugin
    gigyaImgStyles.innerHTML =
        gigyaScreenPrefix +
        ' .sr-only { ' +
        'display: none ' +
        '} ' +
        gigyaScreenPrefix +
        ' .back-arrow :before { top:50px !important}' +
        gigyaScreenPrefix +
        " input[type='text'].gigya-input-text.gigya-error { " +
        'background-image: url(' +
        assetsPath +
        'nemo-error.svg) !important; ' +
        '} ' +
        gigyaScreenPrefix +
        ' .gigya-show-checkmark.gigya-valid { ' +
        'background-image: url(' +
        assetsPath +
        'gigya-valid-tick.svg) !important;  ' +
        '} ' +
        gigyaScreenPrefix +
        ' #teacher-radio  img { ' +
        'content: url(' +
        assetsPath +
        'nemo-teacher.svg) ' +
        '} ' +
        gigyaScreenPrefix +
        ' #learner-radio img { ' +
        'content: url(' +
        assetsPath +
        'nemo-learner.svg) ' +
        '} ' +
        gigyaScreenPrefix +
        ' #parent-radio img { ' +
        'content: url(' +
        assetsPath +
        'nemo-parent.svg) ' +
        '} ' +
        '#nemo-role-selection #learner-radio img { ' +
        'content: url(' +
        assetsPath +
        'nemo-learner-v2.svg); !important;' +
        '} ' +
        '#nemo-role-selection #teacher-radio img { ' +
        'content: url(' +
        assetsPath +
        'nemo-teacher-v2.svg); !important;' +
        '} ' +
        '#nemo-role-selection #parent-radio img { ' +
        'content: url(' +
        assetsPath +
        'nemo-parent-v2.svg); !important;' +
        '} ' +
        gigyaScreenPrefix +
        ', ' +
        gigyaScreenPrefix +
        " * {font-family:'Mulish'!important};" +
        gigyaScreenPrefix +
        " input[type='text'].gigya-input-text.age-field-error " +
        '{ ' +
        'background-image: url(' +
        assetsPath +
        'nemo-error.svg) !important; ' +
        '} ' +
        gigyaScreenPrefix +
        ' .gigya-show-checkmark.gigya-valid { ' +
        'background-image: url(' +
        assetsPath +
        'gigya-valid-tick.svg) !important;  ' +
        '} ' +
        gigyaStylePrefix +
        " button[title='Facebook'] div," +
        gigyaStylePrefix +
        "[gigid='facebook'] button div { " +
        'background-image: url(' +
        assetsPath +
        'Facebook.svg) !important; ' +
        '} ' +
        gigyaStylePrefix +
        " button[title^='Google'] div," +
        gigyaStylePrefix +
        "[gigid^='google'] button div { " +
        'background-image: url(' +
        assetsPath +
        'Google.svg) !important; ' +
        '} ' +
        gigyaStylePrefix +
        " button[title^='Apple'] div," +
        gigyaStylePrefix +
        "[gigid^='apple'] button div { " +
        'background-image: url(' +
        assetsPath +
        'Apple.svg) !important; ' +
        '} ' +
        gigyaScreenPrefix +
        ' .monster-label img { ' +
        'content: url(' +
        assetsPath +
        'nemo-monster.svg); ' +
        '} ' +
        gigyaScreenPrefix +
        ' .shield-label img { ' +
        'content: url(' +
        assetsPath +
        'nemo-shield.webp); ' +
        'width: 93px; ' +
        'height: 99px; ' +
        '} ' +
        gigyaStylePrefix +
        " button[title='Facebook'] img," +
        gigyaStylePrefix +
        "[gigid='facebook'] button img { " +
        'content: url(' +
        assetsPath +
        'Facebook.svg) !important; ' +
        '} ' +
        gigyaStylePrefix +
        " button[title^='Google'] img," +
        gigyaStylePrefix +
        "[gigid^='google'] button img  { " +
        'content: url(' +
        assetsPath +
        'Google.svg) !important; ' +
        '} ' +
        gigyaStylePrefix +
        " button[title^='Apple'] img," +
        gigyaStylePrefix +
        "[gigid^='apple'] button img  { " +
        'content: url(' +
        assetsPath +
        'Apple.svg) !important; ' +
        '} ' +
        '#nemo-role-selection .go-link { ' +
        'mask: url(' +
        assetsPath +
        'arrow-right.svg) no-repeat center; ' +
        '} ' +
        "#nemo-register-learner .nemo-checkbox [type='checkbox']:checked+label:after," +
        "#nemo-register-teacher .nemo-checkbox [type='checkbox']:checked+label:after," +
        "#nemo-register-parent .nemo-checkbox [type='checkbox']:checked+label:after," +
        "#nemo-parental-consent .nemo-checkbox [type='checkbox']:checked+label:after { " +
        'background-image: url(' +
        assetsPath +
        'check-icon.svg) !important; ' +
        '} ' +
        "#nemo-complete-registration .list-fa-icon li:before { " +
        'mask: url(' +
        assetsPath +
        'check-icon.svg) no-repeat center !important; ' +
        '} ' +
        '#nemo-register-teacher #back-icon,' +
        '#nemo-register-parent #back-icon,' +
        '#nemo-register-learner #back-icon,' +
        '#nemo-register-learner-age #back-icon { ' +
        'background-image: url(' +
        assetsPath +
        'arrow-left.svg) !important; ' +
        '} ' +
        '#nemo-forgot-password-success .back-to-login ::before,' +
        '#nemo-reset-password-success .back-to-login ::before { ' +
        'mask: url(' +
        assetsPath +
        'arrow-left.svg) no-repeat center; ' +
        '} ' +
        '#nemo-forgot-password .back-link { ' +
        'mask: url(' +
        assetsPath +
        'arrow-left.svg) no-repeat center; ' +
        '} ' +
        gigyaScreenPrefix +
        ' .tooltip #tooltipLabel img { ' +
        'content: url(' +
        assetsPath +
        'question-circle.svg) !important; ' +
        '} ' +
        '#c1-child-registration .gigya-composite-control.gigya-composite-control-label.child-account-svg-wrapper img { ' +
        'content: url(' +
        assetsPath +
        'child-account.svg) !important; ' +
        '} ' +
        '#c1-child-registration .back-link ::before { ' +
        'mask: url(' +
        assetsPath +
        'arrow-left.svg) !important; ' +
        '} ';

    ['Facebook', 'Google', 'Apple'].forEach((socialIterator) => {
        gigyaImgStyles.innerHTML +=
            '#nemo-register-learner ' +
            gigyaCompositeControlPrefix +
            " button[title='" +
            socialIterator +
            "'] div," +
            '#nemo-register-learner ' +
            gigyaCompositeControlPrefix +
            "[gigid='" +
            socialIterator.toLowerCase() +
            "'] button div," +
            '#nemo-register-teacher ' +
            gigyaCompositeControlPrefix +
            " button[title='" +
            socialIterator +
            "'] div," +
            '#nemo-register-teacher ' +
            gigyaCompositeControlPrefix +
            "[gigid='" +
            socialIterator.toLowerCase() +
            "'] button div," +
            '#nemo-register-parent ' +
            gigyaCompositeControlPrefix +
            " button[title='" +
            socialIterator +
            "'] div," +
            '#nemo-register-parent ' +
            gigyaCompositeControlPrefix +
            "[gigid='" +
            socialIterator.toLowerCase() +
            "'] button div { " +
            'background-image: url(' +
            assetsPath +
            socialIterator +
            '-v2.svg) !important; ' +
            '} ' +
            '#nemo-register-learner ' +
            gigyaCompositeControlPrefix +
            " button[title='" +
            socialIterator +
            "'] img," +
            '#nemo-register-learner ' +
            gigyaCompositeControlPrefix +
            "[gigid='" +
            socialIterator.toLowerCase() +
            "'] button img," +
            '#nemo-register-teacher ' +
            gigyaCompositeControlPrefix +
            " button[title='" +
            socialIterator +
            "'] img," +
            '#nemo-register-teacher ' +
            gigyaCompositeControlPrefix +
            "[gigid='" +
            socialIterator.toLowerCase() +
            "'] button img," +
            '#nemo-register-parent ' +
            gigyaCompositeControlPrefix +
            " button[title='" +
            socialIterator +
            "'] img," +
            '#nemo-register-parent ' +
            gigyaCompositeControlPrefix +
            "[gigid='" +
            socialIterator.toLowerCase() +
            "'] button img { " +
            'content: url(' +
            assetsPath +
            socialIterator +
            '-v2.svg) !important; ' +
            '} ' +
            gigyaLinkAccountStylePrefix +
            " button[title='" +
            socialIterator +
            "'] div {" +
            'background-image: url(' +
            assetsPath +
            socialIterator +
            '.svg) !important; ' +
            '} ' +
            gigyaLinkAccountStylePrefix +
            " button[title='" +
            socialIterator +
            "'] img {" +
            'content: url(' +
            assetsPath +
            socialIterator +
            '.svg) !important; ' +
            '} ';
    });

    document.getElementsByTagName('body')[0].appendChild(gigyaImgStyles);

}
